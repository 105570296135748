import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import HealthSafetyImg from "../../components/images/promotional/health-safety"

export default function HealthSafety() {
    return (
        <>
            <Layout>
                <SEO title="Health & Safety" />
                <Display
                    title="Health & Safety"
                    description="Workplace wellness isn’t just a passing trend. Invest in your staff, clients and volunteers while helping keep them safe and feeling valued."
                    prev="/promotional/apparel-bags"
                    next="/promotional/office-supplies"
                    image={<HealthSafetyImg />}
                />
            </Layout>                
        </>
    )
}
